const google = window.google
const grecaptcha = window.grecaptcha

export { google, grecaptcha }

declare global {
  interface Window {
    // Only available in widget or bgl. In widget is also available in staging.
    // https://github.com/mRaPGmbH/hellocash/blob/f5d573b590f86be3e9c1dff2519a10e773a954e4/model/classes/HtmlTagBuilderCommon.class.php#L7-L7
    grecaptcha?: string
    // only available where charts are drawn (e.g. /intern/cash-register/statistics, or admin)
    google?: {
      charts: {
        loader: {
          VersionSpecific: Record<string, unknown>
        }
      }
      loader: {
        LoadFailure: boolean
      }
      visualization: {
        CssPath: string
        ModulePath: string
        Version: string
        Locale: string
        isDebug: boolean
        isPseudo: boolean
        mapsApiKey: Record<string, unknown>
        data: Record<string, unknown>
        errors: Record<string, unknown>
        events: Record<string, unknown>
        datautils: Record<string, unknown>
      }
    }
  }
}
